<template>
  <div id="app" data-app>
    <!-- Header -->
    <v-app-bar app class="headfoot">
      <img src="../assets/logosp.png" style="width: 10rem;" />
      <v-spacer></v-spacer>
      <v-btn text v-show="renderBills"><v-icon>mdi-calendar-multiple-check</v-icon>{{ eventName.length > 8 ? eventName.slice(0, 8) + '...' : eventName }}</v-btn>
    </v-app-bar>

    <v-dialog v-model="createNewDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          <p>Do you really want to create a new split? This will reset all your current data, if you are not used Save & Split feature and also make sure you have url to comeback.</p>
        </v-card-text>
        <v-card-actions style="justify-content: space-between;">
          <v-btn @click="createNewReport" color="rgb(169 242 172)"  >Yes, I'm sure</v-btn>
          <v-btn @click="closeDialog" color="#E57373">Cancel</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Main Content -->
    <v-main style="padding: 0px;">
      <v-container style="min-height: 100vh;" v-show="!renderBills">
        <v-row style="padding: 0px;">
          <v-col cols="12" class="text-center">
            <img src="../assets/logo2.png" style="width: 10rem;" />
            <br />
            <h2 >Welcome to Split Your Bills - Where Fair Splits Meet Fun!</h2>
            <br />
            <hr>
           
            <v-card class="elevation-30" style="padding: 16px; margin-top: 25px;">
              <v-card-title class="headline">Create Your New Split</v-card-title>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <!-- Event Name -->
                  <v-text-field v-model="eventName" label="What's the occasion? (e.g, Mani's Wedding)" :rules="[rules.required]" required></v-text-field>
                  
                  <!-- Participant Number Input -->
                  <v-text-field v-model="participantNumber" label="Who's joining? (Enter participant count)" type="number" :rules="[rules.required,rules.maxLimit]" min="1" required class="text-center" style="margin-top: 16px;" />
                  
                  <!-- Submit Button -->
                  <v-btn @click="handleSubmit" :disabled="!valid" style="background-color: #afeeee;color: black;" block>Start Splitting!</v-btn>
                </v-form>
              </v-card-text>
            </v-card>

          <br/>
          <hr>
          <br/>
          <h4 variant="subtitle1">
            The easiest way to organize events, track expenses, and split bills among friends!
          </h4>
          </v-col>
        </v-row>
      </v-container>

    

      <!-- Render Participant Cards -->
      <v-container style="min-height: 100vh;" v-show="renderBills">
       

        <v-row >
          <v-col cols="12">  <v-btn   v-show="renderBills" @click="openConfirmationDialog" color="black" block > <v-icon>mdi-calendar-plus</v-icon>Create New Report</v-btn></v-col>
        </v-row>
        <v-row>
          
          <v-col cols="6">  <v-btn @click="addUser" color="black" block ><v-icon>mdi-account-plus</v-icon>Add User</v-btn></v-col>
          <v-col cols="6">   <v-btn @click="saveAndCalculateExpenses" color="black" block > <v-icon>mdi-swap-horizontal</v-icon>Finalize & Split</v-btn></v-col>
        
        </v-row>

          <v-row style="justify-content: center;" v-show="enableTransaction">
            <!-- Balances Table -->
            <v-col cols="12" md="6" style="display: none;">
              <v-card>
                <v-card-title class="font-weight-bold">Balances</v-card-title>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Participant</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(balance, participantId) in balances" :key="participantId">
                      <td>{{ getParticipantName(participantId) }}</td>
                      <td :class="{'text-success': balance >= 0, 'text-error': balance < 0}">{{ balance }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
        
            <!-- Transactions Table -->
            <v-col cols="12" md="6">
              <v-card>
                <v-card-title class="font-weight-bold">Settlements</v-card-title>
                <v-simple-table>
                  <thead>
                    <tr style="background-color: #f0f8ff;">
                      <th>Payee</th>
                      <th class="text-center">Amount to Settle</th>
                      <th>Payer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(transaction, index) in transactions" :key="index">
                      <!-- From Column -->
                      <td>
                        <v-icon left color="blue-grey lighten-2">mdi-account-arrow-right</v-icon>
                        <span>{{ getParticipantName(transaction.from) }}</span>
                      </td>
        
                      <!-- Amount Column -->
                      <td class="text-center" style="font-weight: bold; color: #3f51b5;">
                        {{ transaction.amount }}
                        <v-icon color="blue darken-2" small>mdi-bank</v-icon>
                      </td>
        
                      <!-- To Column -->
                      <td>
                        <v-icon left color="green lighten-2">mdi-account-check</v-icon>
                        <span>{{ getParticipantName(transaction.to) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
         <!-- <v-row v-show="enableTransaction" style="justify-content: center;" class="mb-4">-->
          <v-row v-show="false" style="justify-content: center;" class="mb-4">
            <!-- URL Display with Copy and WhatsApp Share Icons -->
            <v-col cols="12" md="6">
              <v-card outlined>
                <v-card-title>
                  <span class="mr-1">Share your split link with friends to add their Expenses:</span>
                </v-card-title>
                <v-card-actions>
                  <!-- Link Text -->
                  <v-text-field
                    v-model="shareableLink"
                    readonly
                    hide-details
                    solo
                    style="width: 100%;"
                    prepend-inner-icon="mdi-link"
                  ></v-text-field>
        
                  <!-- Copy Icon Button -->
                  <v-btn icon @click="copyLink">
                    <v-icon color="blue darken-2">mdi-content-copy</v-icon>
                  </v-btn>
        
                  <!-- WhatsApp Share Icon Button -->
                  <v-btn
                    icon
                    :href="`https://wa.me/?text=${encodeURIComponent(shareableLink)}`"
                    target="_blank"
                  >
                    <v-icon color="green darken-2">mdi-whatsapp</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        <br>
        <v-row style="justify-content: center;">
          <v-col v-for="(participant, index) in participants" :key="participant.id" cols="12" md="7" class="d-flex justify-center">
            <v-card class="elevation-30" style="width: 100%; padding: 16px;">
              <v-card-text>
                <v-form>
                  <!-- Participant Name -->
                  <v-row align="center">
                    <v-col cols="8">
                      <v-text-field
                        v-model="participant.name"
                        label="Who's in? (Enter a name)"
                        :rules="[rules.required]"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <div><v-icon>mdi-finance</v-icon> <strong>{{ calculateTotalSpent(participant) }}</strong></div>
                    </v-col>
                  </v-row>
                  <!-- Participant Email 
                  <v-text-field v-model="participant.email" label="Participant Email" :rules="[rules.required]" required></v-text-field>
                  -->
                  <!-- Expenses Section -->
                  <div v-for="(expense, expIndex) in participant.expenses" :key="expense.id">
                    <v-row>
                      <!-- Expense Name -->
                      <v-col cols="4">
                        <v-text-field v-model="expense.name" label="Expense for?" :rules="[rules.required]" required></v-text-field>
                      </v-col>
                      
                      <!-- Expense Amount -->
                      <v-col cols="4">
                        <v-text-field v-model="expense.amount" label="Amount Spent?" type="number" :rules="[rules.required]" required></v-text-field>
                      </v-col>
                      
                      <v-col cols="2">
                        <!-- Associated Participants List -->
                        <v-autocomplete
                        v-model="expense.associatedParticipants"
                        :items="participants.map(p => ({ text: p.name, value: p.id }))"
                        multiple
                        chips
                        menu-props="{ closeOnContentClick: false }"
                        prepend-inner-icon="mdi-account-multiple"
                        solo-inverted
                        style="background-color: transparent; min-width: 52px;box-shadow: none !important; border-radius: 0px !important; margin:0px" 
                      >
                        <template v-slot:prepend-inner>
                          <v-icon
                            :style="{
                              color: expense.associatedParticipants.length === participants.length ? 'green' : '#ffad1f',
                              fontSize: '24px', /* Control the icon size */
                              margin: '0' /* Optional, to remove any padding/margin */
                            }"
                          >
                            mdi-account-multiple
                          </v-icon>
                        </template>
                      </v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                        <v-btn @click="removeExpense(index, expIndex)" style="padding: 25px;" icon color="red">
                          <v-icon style="color: #f56c6c;">mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                      <!-- Delete Expense Icon 
                      <v-col cols="2" class="d-flex align-center justify-center">
                        <v-btn @click="removeExpense(index, expIndex)" icon color="red">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>-->
                    </v-row>

                   </div>
                   <v-row>
                    <!-- Add Expense Icon Button -->
                     <v-spacer></v-spacer>
                   <v-col cols="2">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-btn icon @click="addExpense(index)" v-bind="attrs" v-on="on">
                          <v-icon style="color: #2f689a;">mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Another Expense</span>
                    </v-tooltip>
                   </v-col>
                   <v-col cols="2">
 <!-- Delete Participant Icon Button -->
 <v-tooltip bottom>
  <template #activator="{ on, attrs }">
    <v-btn icon @click="openDeleteUserDialog(index)" v-bind="attrs" v-on="on">
      <v-icon style="color: #f56c6c;">mdi-account-minus</v-icon>
    </v-btn>
    <v-dialog v-model="deleteUserDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Are you sure?</v-card-title>
        <v-card-text>
          <p>Are you sure you want to remove this person?</p>
        </v-card-text>
        <v-card-actions style="justify-content: space-between;">
          <v-btn color="rgb(169 242 172)" @click="removeParticipant"  >Yes, I'm sure</v-btn>
          <v-btn color="#E57373" @click="closeDeleteUserDialog" >Cancel</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <span>Remove this Participant</span>
</v-tooltip>
                   </v-col>
    
                 
                  </v-row>
            
                  
      
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      
    </v-main>


    <v-snackbar
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
    bottom
    right
    :color="snackbar.color"
    multi-line
    vertical
  >
    {{ snackbar.message }}
    <v-btn @click="snackbar.show = false" color="white" text>Close</v-btn>
  </v-snackbar>

    <!-- Footer 
    <v-footer app class="headfoot">
      <v-spacer></v-spacer>
      <v-btn text>Privacy Policy</v-btn>
      <v-btn text>Terms of Service</v-btn>
    </v-footer>-->
  </div>
</template>

<script>
export default {
  
  metaInfo: {
    title: 'Split Your Bills - Simplify Group Expenses',
    meta: [
      { property: 'og:title', content: 'Split Your Bills - Simplify Group Expenses' },
      { property: 'og:description', content: 'Easily manage group expenses, events, and bills with Split It. Make settling up a breeze!' },
      { property: 'og:image', content: '/logo.png' }, // Ensure this path points to your logo
      { property: 'og:url', content: 'https://splityourbills.in' },
    ]
  },
  name: 'App',
  data() {
    return {
      createNewDialog: false,
      deleteUserDialog:false,
      participantDeleteIndex:null,
      userEventId:'',
      showCancel: false,
      eventName: '',
      participantNumber: '',
      participants: [],
      renderBills: false,
      valid: false,
      rules: {
        required: value => !!value || 'This field is required',
        maxLimit: value => (value < 50) || 'The value must be less than 50'
      },
      snackbar: {
        show: false,
        message: '',
        timeout: 3000,
        color:'success' // Snackbar stays for 3 seconds
      },
      balances: null,
      transactions: null,
      enableTransaction:false,
      shareableLink:'https://billify-ac550.firebaseapp.com/'
    };
  },
  methods: {
    handleSubmit() {


      this.userEventId='ababbababa'
      if (this.eventName && this.participantNumber) {
        // Create the participants dynamically based on participantNumber
        this.participants = [];
        for (let i = 0; i < this.participantNumber; i++) {
          const participantId = `P${i + 1}`; // Create a unique ID for each participant
          this.participants.push({
            id: participantId,
            name: 'Name ' + (i + 1),
            email: '',
            expenses: [{
              id: `E${i + 1}-1`, // Unique expense ID for initial expense
              name: '',
              amount: '',
              associatedParticipants: this.participants.map(p => p.id), // Select all participants by default
            }],
          });

          for (let i = 0; i < this.participants.length; i++) {
        this.participants[i].expenses[0].associatedParticipants=this.participants.map(p => p.id)

        }

        }

        this.renderBills = true;
        console.log(this.participants);
      } else {
        this.showSnackbar("please fill the Event name and Participants!") 
      }
    },

    // Add a new expense for the participant
    addExpense(participantIndex) {
      const expenseId = `E${participantIndex + 1}-${this.participants[participantIndex].expenses.length + 1}`;
      this.participants[participantIndex].expenses.push({
        id: expenseId,
        name: '',
        amount: '',
        associatedParticipants: this.participants.map(p => p.id), // Select all participants by default
      });
      this.showSnackbar("New Expence list added successfully!")

      console.log(this.participants)
    },

    // Remove an expense for the participant
    removeExpense(participantIndex, expenseIndex) {
      this.participants[participantIndex].expenses.splice(expenseIndex, 1);
      this.showSnackbar("Removed selected Expenses successfully!")
    },
      // Add a new participant to the participants list
      addUser() {
      const newParticipantId = `P${this.participants.length + 1}`;
      const newParticipant = {
        id: newParticipantId,
        name: 'Name ' + (this.participants.length + 1),
        email: '',
        expenses: [{
          id: `E${this.participants.length + 1}-1`,
          name: '',
          amount: '',
          associatedParticipants: this.participants.map(p => p.id), // Include all participants by default
        }],
      };

      this.participants.push(newParticipant);

      for (let i = 0; i < this.participants.length; i++) {
        for(let j=0;j<this.participants[i].expenses.length;j++){
          this.participants[i].expenses[j].associatedParticipants.push(newParticipantId)
        }
        }

      this.showSnackbar("participants added successfully!")


     
    },
    showSnackbar(mes) {
      this.snackbar.message = mes;
      this.snackbar.color = 'info'; // Info color
      this.snackbar.show = true;
    },
    removeParticipant() {
      if (this.participantDeleteIndex !== null) {
      this.participants.splice(this.participantDeleteIndex, 1);
      }
      this.deleteUserDialog=false;
      this.showSnackbar("Participant removed successfully!");
    },
    openConfirmationDialog() {
      this.createNewDialog = true;
    },
    // Close the dialog without doing anything
    closeDialog() {
      this.createNewDialog = false;
    },
    openDeleteUserDialog(index) {
      this.deleteUserDialog = true;
      this.participantDeleteIndex = index;
    },
    // Close the dialog without doing anything
    closeDeleteUserDialog() {
      this.deleteUserDialog = false;
    },
    // Proceed with creating a new report (reset everything and reload the page)
    createNewReport() {
      window.location.href = window.location.origin;     
    },
    getParticipantName(participantId) {
      // Find participant by ID and return their name
      const participant = this.participants.find(p => p.id === participantId);
      return participant ? participant.name : 'Unknown';
    },
    calculateAndSettleExpenses() {
      const totals = {}; // Track total paid by each participant
      const owes = {}; // Track total owed by each participant

      this.participants.forEach(participant => {
        totals[participant.id] = 0;
        owes[participant.id] = 0;
      });

      this.participants.forEach(participant => {
        participant.expenses.forEach(expense => {
          const splitAmount = expense.amount / expense.associatedParticipants.length;

          // Add each participant's share of the expense
          expense.associatedParticipants.forEach(participantId => {
            owes[participantId] += splitAmount;
          });

          // Track how much the participant has paid
          totals[participant.id] += expense.amount;
        });
      });

      // Calculate balances
      const balances = {};
      this.participants.forEach(participant => {
        balances[participant.id] = totals[participant.id] - owes[participant.id];
      });

      // Settle balances
      const debtors = [];
      const creditors = [];

      // Separate participants into debtors and creditors
      Object.keys(balances).forEach(id => {
        const balance = balances[id];
        if (balance < 0) {
          debtors.push({ id, amount: -balance }); // Store debt as positive
        } else if (balance > 0) {
          creditors.push({ id, amount: balance });
        }
      });

      const transactions = [];

      // Match debtors with creditors
      debtors.forEach(debtor => {
        while (debtor.amount > 0) {
          const creditor = creditors.find(c => c.amount > 0);

          if (!creditor) break; // No more creditors to pay

          const amountToPay = Math.min(debtor.amount, creditor.amount);
          transactions.push({
            from: debtor.id,
            to: creditor.id,
            amount: amountToPay
          });

          // Adjust amounts after transaction
          debtor.amount -= amountToPay;
          creditor.amount -= amountToPay;
        }
      });

      // Return results
      return { balances, transactions };


    },
    
    saveAndCalculateExpenses() {
      // Call the calculate and settle function
      const result = this.calculateAndSettleExpenses();
      
      // Set the computed results in the component state
      this.balances = result.balances;

      this.transactions = result.transactions;

      this.enableTransaction=true;

      console.log(this.transactions)
      this.showSnackbar("Split for you bills Successfully Generated!") 
    },
    calculateTotalSpent(participant) {
      return participant.expenses.reduce((total, expense) => total + Number(expense.amount), 0);
    },
     copyLink() {
      navigator.clipboard.writeText(this.shareableLink).then(() => {
        this.showSnackbar("link copied in the clip board!") // Optional: Display a toast notification
      });
    }
  },
  mounted() {
    // This code will run when the component is mounted
    console.log("Component has been mounted!");
    const path = window.location.pathname;

// Capture the part after the last '/'
const id = path.substring(path.lastIndexOf('/') + 1);
if(id !=''){
  this.participants=[
      {
        id: 'P1',
        name: 'Alice Johnson',
        email: 'alice@example.com',
        expenses: [
          {
            id: 'E1-1',
            name: 'Travel',
            amount: 150,
            associatedParticipants: ['P1', 'P2', 'P3']
          },
          {
            id: 'E1-2',
            name: 'Hotel',
            amount: 300,
            associatedParticipants: ['P1']
          }
        ]
      },
      {
        id: 'P2',
        name: 'Bob Smith',
        email: 'bob@example.com',
        expenses: [
          {
            id: 'E2-1',
            name: 'Food',
            amount: 100,
            associatedParticipants: ['P2', 'P3']
          },
          {
            id: 'E2-2',
            name: 'Transport',
            amount: 50,
            associatedParticipants: ['P1', 'P2']
          }
        ]
      },
      {
        id: 'P3',
        name: 'Charlie Brown',
        email: 'charlie@example.com',
        expenses: [
          {
            id: 'E3-1',
            name: 'Supplies',
            amount: 75,
            associatedParticipants: ['P1', 'P3']
          },
          {
            id: 'E3-2',
            name: 'Entertainment',
            amount: 200,
            associatedParticipants: ['P2', 'P3']
          }
        ]
      }
    ];
    this.renderBills=true;
}
console.log(id); 
  }
};
</script>

<style>
.headfoot {
  background-color: paleturquoise !important;
  color: black !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #5698db;
  margin-top: 60px;
}
.app-intro {
  background-color: #f3f3f3;
  padding: 40px;
}
.v-application {
  background-color: #1b93cf;
}
.text-center {
  text-align: center;
}
.headline {
  font-weight: bold;
}
.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-select {
  margin-top: 8px;
}

span.v-chip--select.v-chip.v-chip--clickable.v-chip--no-color.theme--light.v-size--default {
  display: none;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot{
  box-shadow: none !important;
}
.theme--light.v-text-field--solo-inverted>.v-input__control>.v-input__slot {
    background: none !important;
}
.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    width: 100%;
    padding: 8px !important;
}
.v-text-field {
    padding-top: 2px  !important;
    margin-top: 0px !important;
}
.theme--light.v-messages {
    color: rgb(255 0 0 / 60%) !important;
}
.v-sheet.v-card{
    width: 100%;
    padding: 5px !important;
    border-radius: 14px !important;

}
</style>
