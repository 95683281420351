<template>
  <div id="app">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  
  metaInfo: {
    title: 'Split Your Bills - Simplify Group Expenses',
    meta: [
      { property: 'og:title', content: 'Split Your Bills - Simplify Group Expenses' },
      { property: 'og:description', content: 'Easily manage group expenses, events, and bills with Split It. Make settling up a breeze!' },
      { property: 'og:image', content: '/logo.png' }, // Ensure this path points to your logo
      { property: 'og:url', content: 'https://splityourbills.in' },
    ]
  },
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
