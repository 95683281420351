import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'; // Import MDI icons
import 'vuetify/dist/vuetify.min.css';
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

Vue.use(Vuetify);

new Vue({
  vuetify: new Vuetify({
    icons: {
      iconfont: 'mdi', // Use the MDI icon set
    },
  }),
  render: h => h(App),
}).$mount('#app');
